import { render, staticRenderFns } from "./InlineMessageBox.vue?vue&type=template&id=3e1a53f6&scoped=true&"
import script from "./InlineMessageBox.vue?vue&type=script&lang=js&"
export * from "./InlineMessageBox.vue?vue&type=script&lang=js&"
import style0 from "./InlineMessageBox.vue?vue&type=style&index=0&id=3e1a53f6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../buyers/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e1a53f6",
  null
  
)

export default component.exports