<template>
  <div class="social-icons">
    <ShareNetwork
      network="facebook"
      :url="'https://sharing.incredevent.com/' + event.url"
      :title="event.name"
      :description="event.tagline || event.name"
      :quote="event.tagline || event.name"
    >
      <div class="disp-fb">
        <font-awesome-icon :icon="['fab', 'facebook-f']" class="icon" />
      </div>
    </ShareNetwork>

    <ShareNetwork
      network="twitter"
      :url="'https://sharing.incredevent.com/' + event.url"
      :title="event.name"
      :description="event.tagline || event.name"
      :quote="event.tagline || event.name"
    >
      <div class="disp-tw">
        <font-awesome-icon :icon="['fab', 'twitter']" class="icon" />
      </div>
    </ShareNetwork>

    <ShareNetwork
      network="linkedIn"
      :url="'https://sharing.incredevent.com/' + event.url"
      :title="event.name"
      :description="event.tagline || event.name"
      :quote="event.tagline || event.name"
    >
      <div class="disp-li">
        <font-awesome-icon :icon="['fab', 'linkedin-in']" class="icon" />
      </div>
    </ShareNetwork>

    <div class="disp-em" @click="onCopyLink">
      <font-awesome-icon icon="link" class="icon" />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { saveToClipboard } from "@/helpers/clipboard";

library.add(faFacebookF, faTwitter, faLinkedinIn, faLink);

export default {
  name: "ShareLinks",
  components: {
    FontAwesomeIcon
  },
  props: {
    event: Object
  },
  methods: {
    onCopyLink() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          saveToClipboard("http://localhost:3100/" + this.event.url);
          break;
        case "staging":
          saveToClipboard("https://staging.incredevent.com/" + this.event.url);
          break;
        default:
          saveToClipboard("https://sharing.incredevent.com/" + this.event.url);
          break;
      }

      this.$toast("Copied event link to clipboard!", { type: "success" });
    }
  }
};
</script>

<style lang="less" scoped>
.social-icons {
  display: flex;
  margin-left: 10px;
  gap: 8px;

  .disp-fb,
  .disp-tw,
  .disp-li,
  .disp-pn,
  .disp-em,
  .disp-sms {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;

    .icon {
      color: #ffffff;
      width: 25px;
      height: 25px;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  .disp-fb {
    background-color: #4367b2;
  }

  .disp-tw {
    background-color: #54aced;
  }

  .disp-li {
    background-color: #0177b5;
  }

  .disp-pn {
    background-color: #e60122;
  }

  .disp-em {
    background-color: #54aced;
  }

  .disp-sms {
    background-color: green;
  }
}
</style>
