import { render, staticRenderFns } from "./ContactCoordinatorModal.vue?vue&type=template&id=89cafb64&scoped=true&"
import script from "./ContactCoordinatorModal.vue?vue&type=script&lang=js&"
export * from "./ContactCoordinatorModal.vue?vue&type=script&lang=js&"
import style0 from "./ContactCoordinatorModal.vue?vue&type=style&index=0&id=89cafb64&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89cafb64",
  null
  
)

export default component.exports