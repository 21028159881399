import axios from "axios";
import decode from "jwt-decode";
import Vue from "vue";
import Vuex from "vuex";
import { getSystemTheme, setThemeCookie } from "@/helpers/theme";

import {
  getCookies,
  getSessionCookie,
  SESSION_COOKIE
} from "../helpers/auth.js";
import guestlistCart from "./modules/guestlistCart";

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);
axios.interceptors.request.use(
    config => {
      config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
      return config;
    },
    error => Promise.reject(error)
);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { guestlistCart },
  state: {
    ccValues: {
      country: "",
      provider: "",
      rate: 0
    },
    currency: {
      name: "CAD",
      value: 1
    },
    guestEmail: "",
    firstName: "",
    lastName: "",
    cadCurrencyOption: null,
    usdCurrencyOption: null,
    helpVideoSrc: null,
    site: "buyers",
    session_id: null,
    theme: "dark",
    user: { isAuthenticated: false },
    count: 0,
    cart: [],
    bus: new Vue(),
    // FOR TOTESWAP
    toteUpgrade: {},
    // TODO: cart should be redone
    products: [],
    bundles: [],
    eventDates: [],
    discount: [],
    socialdiscount: [],
    socialLinkArray: [],
    socialTypeArray: [],
    cartTotal: 0,
    promoCodeActive: false,
    checkoutTime: undefined,
    chat: {
      defaultUserSuggestions: []
    },

    profileComplete: false,
    confirmPurchase: false,
    loading: false,
    authenticated: false,
    title: "",
    subtitle: ""
  },
  mutations: {
    setCCCountry(state, payload) {
      state.ccValues.country = payload;
    },
    setCCProvider(state, payload) {
      state.ccValues.provider = payload;
    },
    resetCart(state) {
      state.cart = [];
      state.products = [];
    },
    setHelpVideoSrc(state, payload) {
      state.helpVideoSrc = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setCadCurrencyOption(state, payload) {
      state.cadCurrencyOption = payload;
    },
    setUsdCurrencyOption(state, payload) {
      state.usdCurrencyOption = payload;
    },
    setTheme(state, theme) {
      if (theme) {
        if (theme === "system") {
          state.theme = getSystemTheme();
        } else {
          state.theme = theme;
        }

        setThemeCookie(theme);
      }
    },
    updateCart(state, obj) {
      state.cart = obj;
    },
    addDates(state, arr) {
      state.eventDates = arr;
    },
    updateDiscount(state, obj) {
      state.discount = obj;
    },
    addDiscount(state, val) {
      state.discount.push(val);
    },
    clearDiscount(state) {
      state.discount = [];
    },
    removeDiscount(state, val) {
      state.discount.splice(val, 1);
    },
    updateSocialDiscount(state, obj) {
      state.socialdiscount = obj;
    },
    addSocialDiscount(state, val) {
      state.socialdiscount.push(val);
    },
    clearSocialDiscount(state) {
      state.socialdiscount = [];
    },
    removeSocialDiscount(state, val) {
      state.socialdiscount.splice(val, 1);
    },
    codeApplied(state, bool) {
      state.promoCodeActive = bool;
    },
    checkoutDeadline: (state, time) => {
      state.checkoutTime = time;
    },
    destroyDeadline: state => {
      state.checkoutTime = undefined;
    },
    updateUser: (state, updatedUser) => {
      state.user.email = updatedUser.email;
    },
    updateUserProperty: (state, obj) => {
      state.user[obj.property] = obj.value;
    },
    updateInterests: (state, obj) => {
      state.user.interests = obj.interests;
      state.user.pendingInterests = obj.pendingInterests;
    },
    updateProfileImg: (state, data) => {
      state.user.profile_img = data;
    },
    setUser: (state, data) => {
      state.user = data;
      state.user.isAuthenticated = true;
      state.user.name = data.first_name + " " + data.last_name;
      state.user.upcomingTickets = data.upcomingTickets;
      state.user.location = data.location;
      state.user.phone = data.phone;
      state.user.seats_enabled = data.seats_enabled;
    },
    setData: (state, data) => {
      state.session_id = data.session_id;
      state.user.activated = data.activated;
      state.profileComplete = data.profileComplete;
      state.user.coordinator = data.coordinator;
      state.profileComplete = data.profileComplete;
    },
    //TODO improve logout
    setLogout: (state, data) => {
      state.user = { isAuthenticated: false };
      state.session_id = null;
    },
    setUserName(state, data) {
      state.user = {
        ...state.user,
        first_name: data.firstName,
        last_name: data.lastName,
        name: data.firstName + " " + data.lastName
      };
    },
    setTitle(state, title) {
      state.title = title;
    },
    setSubtitle(state, subtitle) {
      state.subtitle = subtitle;
    }
  },
  actions: {
    resetCart(state) {
      state.commit("resetCart");
    },
    updateHelpVideoSrc(state, payload) {
      state.commit("setHelpVideoSrc", payload);
    },
    updateCurrency(state, payload) {
      state.commit("setCurrency", payload);
    },
    getCadCurrencyOption(state, payload) {
      state.commit("setCadCurrencyOption", payload);
    },
    getUsdCurrencyOption(state, payload) {
      state.commit("setUsdCurrencyOption", payload);
    },
    updateCheckout({ commit }, obj) {
      commit("updateCart", obj);

      let temp = [];
      let fees = 4;
      let total = 0;
      if (!obj.discount) {
        for (let x = 0; x < obj.length; x++) {
          temp.push(obj[x].order * (obj[x].price + fees));
        }
      } else {
        for (let x = 0; x < obj.order.length; x++) {
          temp.push(
              obj.order[x].order * (obj.order[x].price * obj.discount + fees)
          );
        }
      }

      for (let x = 0; x < temp.length; x++) {
        total += temp[x];
      }

      commit("updateCartTotal", total.toFixed(2));
    },
    updateDates({ commit }, obj) {
      let included = false;
      let dates = this.state.eventDates;

      if (dates == null) {
        commit("addDates", [obj]);
      } else {
        for (let x = 0; x < dates.length; x++) {
          if (dates[x].eventUrl == obj.eventUrl) {
            dates[x] = obj;
            included = true;
          }
        }

        if (included == false) {
          dates.push(obj);
        }

        commit("addDates", dates);
      }
    },
    removeDate({ commit }, obj) {
      let dates = this.state.eventDates;
      let index;

      for (let x = 0; x < dates.length; x++) {
        if (dates[x].eventUrl == obj.eventUrl) {
          index = x;
        }
      }

      dates.splice(index, 1);
      commit("addDates", dates);
    },

    logout({ commit }) {
      commit("setLogout", null);
    },
    toggleTheme({ commit, state }) {
      commit("setTheme", state.theme === "dark" ? "light" : "dark");
    }
  },
  getters: {
    getSelectedTickets(state) {
      return event => state.cart.filter(t => t.url === event);
    },
    getGuestEmail(state) {
      return state.guestEmail;
    },
    getSelectedProducts(state) {
      return event => state.products.filter(p => p.event_url === event);
    },
    getSelectedBundles(state) {
      return event => state.bundles.filter(p => p.event_url === event);
    },
    getSelectedGuestlistItems(state) {
      return event =>
          state.guestlistCart.guestlist_cart.filter(p => p.event_url === event);
    },
    getGuestlistForm(state) {
      return () => state.guestlistCart.guestlist;
    }
  }
});
