<template>
  <div class="splash">
    <div class="inner-container">
      <div class="panel" id="Buyers">
        <div class="panel-inner">
          <div class="panel-content">
            <div class="logo-wrapper">
              <img
                src="@/images/logos/IncredEvent-Logo-Dark.webp"
                alt="incredevent.com"
              />
            </div>
            <div class="slogan-wrapper">
              <h1>
                Incredible Events,
                <span>Incredible Experiences</span>
              </h1>
            </div>
            <div class="button-wrapper">
              <div class="info">
                <h4>Browse Local Events</h4>

                <p>
                  A Simple & Social Platform for Ticket Buyers! Find the hottest
                  events in your city or anywhere in North America. Buying
                  tickets takes less than a minute!
                </p>
                <diyobo-button
                  class="secondary bold"
                  txt="Buy Tickets"
                  @click="toEvents"
                />
              </div>
              <div class="info">
                <h4>Event Planners</h4>
                <p>
                  A Simple & Powerful Platform for Event Planners! Venues,
                  teams, corporations, planners ... anyone that plans events
                  will LOVE INCREDEVENT! Our fees are fair, our features are
                  plentiful, and our support is awesome!
                </p>
                <diyobo-button
                  class="primary bold splash-page-button"
                  txt="Sell Tickets"
                  @click="toPlanners"
                />
              </div>
              <div class="info">
                <h4>Event Promoters</h4>
                <p>
                  Our Promoter Dashboard allows you to track the link activity
                  by your promoters, vendors, affiliates, and influencers. Track
                  sales, collections and much more.
                </p>
                <diyobo-button
                  class="tertiary bold"
                  txt="Promote Events"
                  @click="toPromoters"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="links-wrapper">
            <router-link to="/terms">Terms of Use</router-link>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
            <router-link to="/cookies">Cookies Policy</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "https://use.typekit.net/mqb5euw.css";

.splash {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  color: #fff;

  .inner-container {
    height: 100%;

    .panel {
      width: 100%;
      position: relative;

      .info {
        .splash-page-button {
          background: var(--primary-green);
        }
      }

      &#Buyers {
        // margin: 0 0.35em;
        min-height: 100vh;
        background-image: url("../img/splash/TTSplashBG.jpg");
        background-size: cover;
        .logo-wrapper {
          width: 100%;
          // padding: 65px 0;
          img {
            display: block;
            margin: auto;
            max-width: 375px;
            width: 75%;
          }
        }
      } // end Buyers panel

      .panel-inner {
        .panel-content {
          // display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          width: 100%;
          height: 100%;
          padding: 65px 0;

          .slogan-wrapper,
          .button-wrapper {
            flex-basis: 100%;
          } // end wrappers

          .button-wrapper {
            // position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            // margin-bottom: 40px;

            .sell-btn {
              margin-left: 20px;
            }
            .info {
              backdrop-filter: blur(4px);
              padding: 20px;
              margin: 10px;
              max-width: 450px;
              width: 100%;
              border: 1px solid #33373f;
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.1);
              background-image: linear-gradient(
                180deg,
                transparent 30%,
                rgba(0, 0, 0, 0.25)
              );

              box-shadow: 0px 4px 11px 0 #00000082;

              button {
                margin: auto;
              }
              h4 {
                margin: 0;
                color: white;
              }
              p {
                margin: 20px 0;
                color: #b1b1b1;
                min-height: 90px;
              }
            }
          }
          .slogan-wrapper {
            // position: relative;
            margin: 65px 0;
            bottom: 10%;

            h1 {
              color: #fff;
              margin: 0;
              .green {
                color: #43b679;
              }
            }
          }
        } // end panel-content

        .description {
          font-weight: 500;
          font-size: 12px;
          text-shadow: 1px 1px 1px #0000006e;
        } // end description
      } // end panel-inner
      // end footer
    } // end panel
  }

  .footer {
    flex-basis: 100%;
    left: 0;
    right: 0;
    // position: absolute;
    bottom: 0;
    box-shadow: none;
    color: #b1b1b1;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    padding: 0 0 30px;

    a {
      padding: 0 6px;
      color: inherit;
      text-decoration: none;

      &:nth-child(2) {
        border: 1px solid #858585c0;
        border-top: 0;
        border-bottom: 0;
      }
    } // end anchor
  }

  // end inner-container
}
@media only screen and (max-width: 924px) {
  .splash > .inner-container > .panel {
    height: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .splash {
    .inner-container {
      height: initial;
      .panel {
        .panel-inner {
          .panel-content {
            .slogan-wrapper {
              margin: 30px 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 924px) {
  .splash {
    .inner-container {
      // margin-bottom: 20px;
      .panel-content {
        padding: 30px 0 !important;
      }
    }
  }
}
</style>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "splash-page",
  head() {
    return {
      title: "Create & Manage Events Online • Sell Tickets • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "Create & Manage Events. Sell & Promote Tickets. An industry-leading platform for the event planning industry. Start saving time & money with INCREDEVENT",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },

  layout: "splash",
  components: {
    DiyoboButton,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  meta: {
    allowAnonymous: true,
  },
  methods: {
    toEvents() {
      this.$router.push("/events");
    },
    toPlanners() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          window.location.href = "http://localhost:3200";
          break;
        case "staging":
          window.location.href = "https://planners.staging.incredevent.com";
          break;
        default:
          window.location.href = "https://planners.incredevent.com";
          break;
      }
    },
    toPromoters() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          window.location.href = "http://localhost:3300";
          break;
        case "staging":
          window.location.href = "https://promoters.staging.incredevent.com";
          break;
        default:
          window.location.href = "https://promoters.incredevent.com";
          break;
      }
    },
  },
};
</script>
