<template>
  <div>
    <alert-modal ref="alert"/>
    <modal
      class="alert"
      :active="active"
      :title="title"
      :confirm="confirm"
      :closable="closable"
      v-on:close="close"
    >
      <FormulateForm ref="form" name="contactForm" v-model="formValues" @submit="okay">
        <FormulateInput
          type="text"
          name="name"
          label="Name"
          placeholder="Name"
          validation="required"
          autocomplete="false"
        />
        <FormulateInput
          type="email"
          name="email"
          label="Email"
          placeholder="411@incredevent.com"
          validation="required|email"
          autocomplete="false"
        />
        <FormulateInput
          type="textarea"
          name="message"
          label="Message"
          placeholder="Message"
          validation="required"
          autocomplete="false"
        />

        <div class="buttons">
          <FormulateInput
            class="button"
            v-if="closable"
            type="button"
            name="Cancel"
            @click="close"
          />
          <div class="action-btns">
            <FormulateInput
              class="button"
              type="submit"
              :name="confirm"
            />
          </div>
        </div>
      </FormulateForm>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import AlertModal from "@/components/modals/AlertModal.vue";

export default {
  name: "ticket-options",
  components: {
    Modal,
    DiyoboButton,
    AlertModal
  },
  data() {
    return {
      title: "",
      confirm: "Okay",
      active: false,
      onConfirm: null,
      closable: true,
      contactType: "",
      formValues: {
        name: null,
        email: null,
        message: null,
        url: ""
      }
    };
  },
  methods: {
    open(args) {
      this.active = true;
      this.title = args.title || "Heads Up";
      this.confirm = args.confirm || "Send";
      this.onConfirm = args.onConfirm;
      this.closable = !!args.closable;
      this.contactType = args.contactType || "tickets";
      this.url = args.url || "";
      document.body.style.overflow = "hidden";
    },
    close() {
      this.active = false;
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      if (this.contactType === "tickets") {
        this.contactTickettote(
          this.url,
          this.formValues.name,
          this.formValues.email,
          this.formValues.message
        );
        this.$refs.alert.open({
          title: "Success!",
          msg: "Your message has been sent."
        });
      } else {
        this.contactCoordinator(
          this.url,
          this.formValues.name,
          this.formValues.email,
          this.formValues.message
        );
        this.$refs.alert.open({
          title: "Success!",
          msg: "Your message has been sent."
        });
      }
      this.$formulate.reset("contactForm");

      this.close();
    },
    contactCoordinator(url, name, email, message) {
      this.$axios
        .post("/emails/contact-planner", {
          url,
          name,
          email,
          message
        })
        .then(response => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent."
            });
          }
        })
        .catch(e => {
          this.ccmodalE = e;
        });
    },
    contactTickettote(url, name, email, message) {
      this.$axios
        .post("/emails/contact-ticketquestions", {
          url,
          name,
          email,
          message
        })
        .then(response => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent."
            });
          }
        })
        .catch(e => {
          this.ccmodalE = e;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.alert {
  z-index: 1001;

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}

.buttons {
  display: inline-flex;
  justify-content: space-between;
  .action-btns {
    .button {
      margin-left: 20px;
    }
  }
}
</style>
