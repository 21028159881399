<template>
  <div>
    <router-view
        class="main-cont"
    />
  </div>
</template>

<script>
export default {
  name: "layout-splash",
  metaInfo() {
    return {
      title: "",
      titleTemplate: (title) =>
        title ? `${title} • INCREDEVENT` : "INCREDEVENT",
    };
  },
};
</script>
