import Vue from "vue";
import Router from "vue-router";
import Index from "../views/index.vue";
import store from "../store/index";
import SplashLayout from "../layouts/splash.vue";
import DefaultLayout from "../layouts/default.vue";
import EmbedLayout from "../layouts/embed.vue";

import { isAuthenticated } from "../helpers/auth";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: Index
    // },
    {
      path: "/",
      component: SplashLayout,
      children: [
        {
          path: "",
          component: Index,
          name: "home",
          meta: { allowAnonymous: true },
        },
        // other routes for default layout
      ],
    },
    {
      path: "/",
      component: DefaultLayout,
      children: [
        {
          path: "/login-register",
          name: "login-register",
          component: () =>
            import(/* webpackChunkName: "about-us" */ "../views/login.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/about-us",
          name: "about-us",
          component: () =>
            import(/* webpackChunkName: "about-us" */ "@/views/About.vue"),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: "/test-protecht",
          name: "test-protecht",
          component: () =>
            import(
              /* webpackChunkName: "about-us" */ "../views/test-protecht.vue"
            ),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: "/not-found",
          name: "not-found",
          component: () =>
            import(/* webpackChunkName: "about-us" */ "@/views/NotFound.vue"),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: "events",
          component: () => import("../views/events.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/contact-us",
          name: "contact-us",
          component: () => import("@/views/Contact.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/security",
          name: "security",
          component: () => import("@/views/Security.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/how-it-works",
          name: "how-it-works",
          component: () => import("@/views/HowItWorks.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/blog",
          name: "blog",
          component: () => import("@/views/Blog.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/views/FAQ.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/terms",
          name: "terms",
          component: () => import("@/views/Terms.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/privacy-policy",
          name: "privacy-policy",
          component: () => import("@/views/Privacy.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/buyer-features",
          name: "buyer-features",
          component: () => import("@/views/BuyerFeatures.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/planner-features",
          name: "planner-features",
          component: () => import("@/views/PlannerFeatures.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/refunds",
          name: "refunds",
          component: () => import("@/views/Refunds.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/cookies",
          name: "cookies",
          component: () => import("@/views/Cookies.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/my-tickets",
          name: "my-tickets",
          component: () => import("../views/my-tickets.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/views/Profile.vue"),
        },
        {
          path: "/accessibility-statement",
          name: "accessibility-statement",
          props: true,
          component: () => import("@/views/AccessibilityStatement.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/verify",
          name: "verify",
          meta: { allowAnonymous: true },
          component: () => import("@/views/Verify.vue"),
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/views/Notifications.vue"),
        },
        {
          path: "/attendee-info/:order?/attendees",
          name: "attendees-order",
          // meta: { allowAnonymous: false },
          component: () =>
            import("../views/attendee-info/_order/attendees.vue"),
        },
        {
          path: "/attendee-info/:attendeeID?/attendees-confirmation",
          name: "attendees-confirmation",
          meta: { allowAnonymous: false },
          component: () =>
            import(
              "../views/attendee-info/_attendeeID/attendees-confirmation.vue"
            ),
        },
        //TESTM
        {
          path: "/tote-swap/",
          props: true,
          component: () => import("@/views/tote-swap/Index.vue"),
          meta: { allowAnonymous: false },

          children: [
            {
              path: "/",
              name: "tote-swap",
              redirect: "upgrade/",
            },
            {
              path: "upgrade/:url?",
              name: "upgrade",
              component: () => import("@/views/tote-swap/Upgrade.vue"),
            },
            {
              path: "checkout",
              name: "checkout",
              component: () => import("@/views/tote-swap/Checkout.vue"),
            },
            {
              path: "exchange/:url?",
              name: "exchange",
              component: () => import("@/views/tote-swap/Exchange.vue"),
            },
            {
              path: "transfer",
              name: "transfer",
              component: () => import("@/views/tote-swap/Transfer.vue"),
            },
            {
              path: "refund",
              path: "refund/:url?",
              name: "refund",
              component: () => import("@/views/tote-swap/Refund.vue"),
            },
          ],
        },
        {
          path: "/embed/guestlist/confirm",
          component: () => import("../views/embed/guestlist/confirm.vue"),
          meta: { allowAnonymous: true },
          name: "embed-guestlist-confirm",
        },
        {
          path: "/embed/guestlist/:url?",
          component: () => import("../views/embed/guestlist/_url.vue"),
          meta: { allowAnonymous: true },
          name: "embed-guestlist-url",
        },
        {
          path: "/embed/counter",
          component: () => import("../views/embed/counter.vue"),
          name: "counter",
          meta: { allowAnonymous: true },
        },
        {
          path: "/brand/:url?",
          component: () => import("../views/brand/_url.vue"),
          meta: { allowAnonymous: true },
          name: "brand-url",
        },
        {
          path: "/pv/:url?",
          component: () => import("../views/pv/_url.vue"),
          meta: { allowAnonymous: true },
          name: "pv-url",
        },
        {
          path: "/refund/:id?",
          component: () => import("../views/refund/_id.vue"),
          meta: { allowAnonymous: true },
          name: "refund-id",
        },
        {
          path: "/order/:order?/qna",
          component: () => import("../views/order/_order/qna.vue"),
          meta: { allowAnonymous: true },
          name: "order-order-qna",
        },
        {
          path: "/:event",
          component: () => import("../views/_event/index.vue"),
          meta: { allowAnonymous: true },
        },
        {
          path: "/:event/checkout",
          component: () => import("../views/_event/checkout.vue"),
          meta: { allowAnonymous: true },

          children: [
            {
              path: "",
              component: () => import("../views/_event/checkout.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout",
            },
            {
              path: "account-information",
              component: () =>
                import("../views/_event/checkout/account-information.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-account-information",
            },
            {
              path: "add-ons",
              component: () => import("../views/_event/checkout/add-ons.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-add-ons",
            },
            {
              path: "attendees",
              component: () => import("../views/_event/checkout/attendees.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-attendees",
            },
            {
              path: "billing",
              component: () => import("../views/_event/checkout/billing.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-billing",
            },
            {
              path: "refund-protection",
              component: () =>
                import("../views/_event/checkout/refund-protection.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-refund-protection",
            },
            {
              path: "additional-questions",
              component: () =>
                import("../views/_event/checkout/additional-questions.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-additional-questions",
            },
            {
              path: "discounts",
              component: () => import("../views/_event/checkout/discounts.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-discounts",
            },
            {
              path: "purchase-summary",
              component: () =>
                import("../views/_event/checkout/purchase-summary.vue"),
              meta: { allowAnonymous: true },

              name: "event-checkout-purchase-summary",
            },
            {
              path: "qna",
              component: () => import("../views/_event/checkout/qna.vue"),
              meta: { allowAnonymous: true },
              name: "event-checkout-qna",
            },
          ],
        },
        {
          path: "/:event/confirmation",
          component: () => import("../views/_event/confirmation.vue"),
          meta: { allowAnonymous: true },
          name: "event-confirmation",
        },
        {
          path: "/:event/waiver",
          component: () => import("../views/_event/waiver.vue"),
          meta: { allowAnonymous: true },
          name: "event-waiver",
        },
      ],
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  // function stepperRoute() {
  //     // let steps = store.getters.getStepperProperties();
  //     // if (!steps) {
  //     //   store.dispatch("loadUser");
  //     //   store.watch(store.getters.getStepperProperties, function() {
  //     //     if (store.getters.getStepperProperties()) {
  //     //       proceed();
  //     //     }
  //     //   });
  //     // } else {
  //     proceed();
  //     // }
  // }

  function proceed() {
    next();
  }

  try {
    if (
      to.name !== "login-register" &&
      !to.meta.allowAnonymous &&
      !store.state.user.isAuthenticated
    ) {
      next({
        path: "/login-register",
        query: {
          redirect: to.fullPath,
          guest: to.query.guest ?? null,
        },
      });
    } else {
      proceed();
      // if (store.state.user.email) {
      //     proceed();
      // } else {
      //     store.watch(
      //         () => store.getters.getUser(),
      //         function () {
      //             if (store.getters.getStepperProperties()) {
      //                 proceed();
      //             }
      //         }
      //     );
      //     next();
      // }
    }
  } catch (err) {
    console.log(err);
  }
});

router.afterEach((to, from) => {
  const toParts = to.path.split("/");
  const fromParts = from.path.split("/");

  // this handles page with child paths, not 100% sure this is fool proof
  if (
    toParts.length > 1 &&
    fromParts.length > 1 &&
    toParts[1] === fromParts[1]
  ) {
    return;
  }

  // reset navbar meta when navigating
  store.commit("setTitle", "");
  store.commit("setSubtitle", "");
  store.commit("setHelpVideoSrc", "");
});

export default router;
